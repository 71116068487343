import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'confirmation',
  templateUrl: './confirmation.dialog.html',
  styleUrls: ['./confirmation.dialog.scss'],
})
export class ConfirmationDialog {
  constructor(private matDialogRef: MatDialogRef<ConfirmationDialog>) {}

  public continue(): void {
    this.matDialogRef.close(true);
  }

  public cancel(): void {
    this.matDialogRef.close(false);
  }
}
