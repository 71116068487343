import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RouterService {
  constructor(private router: Router, private route: ActivatedRoute) {
    this.router.events.subscribe(() => {
      this.mainMenu =
        this.router.url.split('/').filter((part) => part !== '')[1] ?? '';
      this.subMenu =
        this.router.url.split('/').filter((part) => part !== '')[2] ?? '';
    });
  }

  public mainMenu: string;
  public subMenu: string;

  public absolutNavigation(path: string): void {
    this.router.navigate([path]);
  }

  public relativeNavigation(subMenu: string): void {
    this.router.navigate(['hu', this.mainMenu, subMenu]);
  }
}
