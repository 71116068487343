<div class="booking-arrival-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-header"></div>
  <div class="action-panel">
    <div class="arrival-wrapper">
      <ng-container *ngFor="let guest of guests">
        <arrival-card
          #guestForms
          [guest]="guest"
          [roomId]="selectedRoom"
          (isValid)="checkValidations()"
          (addGuest)="addSingleGuest($event)"
          *ngIf="selectedRoom === guest.room_id || !guest.guest_id"
        ></arrival-card>
      </ng-container>
      <button (click)="addAllGuests()" [disabled]="!areFormsValid">
        Érkeztetés
      </button>
    </div>
  </div>
  <div class="action-footer"></div>

  <div class="selector-header"></div>
  <div class="selector-wrapper">
    <div class="room-panel-wrapper">
      <div
        *ngFor="let room of rooms | keyvalue"
        class="room-panel"
        [ngClass]="{
          selected: room.key === selectedRoom?.toString(),
          success: rooms[room.key].arrived
        }"
        (click)="selectRoom(room.key)"
      >
        <div class="room-name">
          <span>{{ roomService.getRoomName(room.key) }}</span>
        </div>

        <div class="status">
          <span>{{ rooms[room.key] | bookingStatus : "hu" }}</span>
        </div>

        <div *ngIf="!rooms[room.key].arrived" class="date-wrapper">
          <div class="date">
            <span>{{ rooms[room.key].arrival_date }}</span>
          </div>
          <span class="num-of-nights"
            >{{
              calendarService.calculateDaysDifference(
                rooms[room.key].arrival_date,
                rooms[room.key].departure_date
              )
            }}
            éj</span
          >

          <div class="date">
            <span>{{ rooms[room.key].departure_date }}</span>
          </div>
        </div>

        <span *ngIf="rooms[room.key].arrived" class="success-message"
          >Megérkezett</span
        >

        <div class="details">
          <div class="occupancy-wrapper">
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ rooms[room.key].adults }}</span>
              </div>
              <div>
                <i class="adult-icon"></i>
              </div>
            </div>
            <div class="occupancy-block">
              <div class="occupancy">
                <span>{{ rooms[room.key].children }}</span>
              </div>
              <div>
                <i class="child-icon"></i>
              </div>
            </div>
          </div>
          <div *ngIf="!rooms[room.key].arrived" class="amount-block">
            <input
              disabled
              class="amount"
              type="number"
              [(ngModel)]="rooms[room.key].gross_price"
            />
            <input type="text" class="huf-label" value="HUF" disabled />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="button-wrapper">
    <button type="submit" [disabled]="false" class="primary-button">
      <span *ngIf="!isLoading">Érkeztetés</span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
