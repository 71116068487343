import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent {
  constructor(
    private route: Router,
    public hotelService: HotelService,
    public focusService: FocusService
  ) {}

  public hotel: string = 'Károlyi panzió';
  public hotels: string[] = ['Károlyi panzió', '5 star hotel'];

  public navigate(path: string): void {
    this.route.navigate(['hu/' + path]);
    this.focusService.selectedMenu = path;
  }

  public hotelSelected(hotel: string) {
    this.hotelService.selectHotel({
      name: hotel,
      hotel_id: 1,
      room_limit: 1,
      last_concluded_day: '2023-12-12',
    });
  }
}
