import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BookingInterface, RoomInterface } from '../@Interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from '../@dialogs/confirmation/confirmation.dialog';
import { HotelService } from './hotel.service';
import { environment } from '../@environments/environtment';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class RoomService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private matDialog: MatDialog,
    private dateService: DateService
  ) {}

  public rooms: RoomInterface[] = [];

  public roomOOO: any = [];
  public roomOOS: any = [];
  public bookingOOO: any = [];
  public bookingOOS: any = [];

  /* <--API CALLS--> */

  public getRooms(): void {
    this.http
      .get<RoomInterface[]>(
        `${environment.url}api/hotels/${this.hotelService.selectedHotel.hotel_id}/rooms`
      )
      .subscribe({
        next: (rooms: RoomInterface[]) => {
          this.rooms = rooms;
        },
        error: () => {
          //todo handle error
        },
      });
  }

  public newRoom(form: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<RoomInterface[]>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotel.hotel_id}/rooms`,
          form
        )
        .subscribe({
          next: (rooms) => {
            this.rooms = rooms;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public editRoom(form: any, roomId: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.localUpdateRoom(form, roomId);
      this.http
        .put<RoomInterface[]>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotel.hotel_id}/rooms/${roomId}`,
          form
        )
        .subscribe({
          next: (rooms) => {
            this.rooms = rooms;
            resolve();
          },
          error: (error) => {
            reject();
          },
        });
    });
  }

  public deleteRoom(room: RoomInterface): void {
    this.matDialog
      .open(ConfirmationDialog)
      .afterClosed()
      .subscribe((choice) => {
        if (choice) {
          this.http
            .delete<RoomInterface[]>(
              `${environment.url}api/hotels/${this.hotelService.selectedHotel.hotel_id}/rooms/` +
                room.room_id
            )
            .subscribe({
              next: (rooms) => {
                this.rooms = rooms;
              },
              error: (error) => {},
            });
        }
      });
  }

  public getRoomOOO(roomId: number | string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/ooo?room_id=${roomId}`
        )
        .subscribe({
          next: (ooo: any) => {
            this.roomOOO = ooo;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getRoomOOS(roomId: number | string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/oos?room_id=${roomId}`
        )
        .subscribe({
          next: (oos: any) => {
            this.roomOOS = oos;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public createRoomOOO(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/ooo`,
          body
        )
        .subscribe({
          next: (roomOOO) => {
            this.roomOOO = roomOOO;
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public createRoomOOS(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/oos`,
          body
        )
        .subscribe({
          next: (roomOOS) => {
            this.roomOOS = roomOOS;
            resolve();
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  public deleteRoomOOO(oooId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/ooo/${oooId}`
        )
        .subscribe({
          next: (updatedOOO) => {
            this.roomOOO = updatedOOO;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public deleteRoomOOS(oosId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/oos/${oosId}`
        )
        .subscribe({
          next: (updatedOOS) => {
            this.roomOOS = updatedOOS;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getBookingOOO(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/ooo`
        )
        .subscribe({
          next: (ooo: any) => {
            this.bookingOOO = ooo;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public getBookingOOS(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .get(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/oos`
        )
        .subscribe({
          next: (oos: any) => {
            this.bookingOOS = oos;
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  /* <--API CALLS--> */

  /* <--HELPER FUNCTIONS--> */

  public isRoomAvailable(
    date: Date,
    roomId: number,
    bookings: BookingInterface[],
    bookingId: number
  ): boolean {
    for (const booking of bookings) {
      if (booking.room_id === roomId && booking.booking_id !== bookingId) {
        const arrivalDate = new Date(booking.arrival_date).getTime();
        const departureDate = new Date(booking.departure_date).getTime();
        const questionedDate = date.getTime();

        if (arrivalDate <= questionedDate && questionedDate <= departureDate) {
          return false;
        }
      }
    }
    if (this.isDateInOOO(date, roomId)) {
      return false;
    }
    return true;
  }

  public getRoomName(roomId: string): string {
    const roomIdNumber = parseInt(roomId, 10);
    const room = this.rooms.find((room) => room.room_id === roomIdNumber);
    return room ? room.name : 'Room Not Found';
  }

  public isDateInOOO(date: Date, roomId: number): boolean {
    const searchDate = this.dateService.formatDate(date);

    const isDateOOO: boolean = this.bookingOOO.some(
      (ooo: any) =>
        new Date(ooo.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(ooo.end_date).getTime() &&
        roomId === ooo.room_id
    );
    return isDateOOO;
  }

  public isDateInOOS(date: Date, roomId: number): boolean {
    const searchDate = this.dateService.formatDate(date);
    const isDateOOS: boolean = this.bookingOOS.some(
      (oos: any) =>
        new Date(oos.start_date).getTime() <= new Date(searchDate).getTime() &&
        new Date(searchDate).getTime() <= new Date(oos.end_date).getTime() &&
        roomId === oos.room_id
    );
    return isDateOOS;
  }

  private localUpdateRoom(updatedRoom: RoomInterface, roomId: string): void {
    const roomIndex = this.rooms.findIndex(
      (room) => room.room_id === Number(roomId)
    );
    if (roomIndex !== -1) {
      this.rooms[roomIndex] = Object.assign(
        {},
        this.rooms[roomIndex],
        updatedRoom
      );
      console.log('Room updated successfully.');
    } else {
      console.log('Room with the provided room_id not found.');
    }
  }

  /* <--HELPER FUNCTIONS--> */
}
