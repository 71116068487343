import { Injectable } from '@angular/core';
import { HotelInterface } from '../@Interfaces/hotel.interface';
import { HttpClient } from '@angular/common/http';
import { environment } from '../@environments/environtment';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class HotelService {
  constructor(private http: HttpClient, private dateService: DateService) {}

  public selectedHotel: HotelInterface;
  public selectedHotelId: number = 1;
  public selectedHotelRoomLimit: number = 5;
  public hotels: HotelInterface[];

  public selectHotel(hotel: HotelInterface): void {
    this.selectedHotel = hotel;
    this.selectedHotelId = hotel.hotel_id;
    this.selectedHotelRoomLimit = hotel.room_limit;
  }

  public getHotels(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.http
        .get<HotelInterface[]>(`${environment.url}api/hotels`)
        .subscribe({
          next: (hotels) => {
            if (!hotels) {
              reject();
            }

            this.hotels = hotels;
            if (!this.selectedHotel) {
              this.hotels.forEach((hotel) => {
                hotel.last_concluded_day = this.dateService.addDayToStringDate(
                  hotel.last_concluded_day,
                  1
                );
              });
              this.selectedHotel = this.hotels[0];
              this.selectedHotelId = this.hotels[0].hotel_id;
              this.selectedHotelRoomLimit = this.hotels[0].room_limit;
              this.selectedHotel.name = this.hotels[0].name;
            }
            resolve(true);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public concludeDay(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<{ success: boolean; concluded_day: string }>(
          `${environment.url}api/hotels/${this.selectedHotel.hotel_id}/conclude-day`,
          {
            date: this.selectedHotel.last_concluded_day,
          }
        )
        .subscribe({
          next: (response: { success: boolean; concluded_day: string }) => {
            this.selectedHotel.last_concluded_day =
              this.dateService.addDayToStringDate(response.concluded_day, 1);
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
