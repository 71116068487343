import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RoomInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';

@Component({
  selector: 'rooms-overview',
  templateUrl: './rooms-overview.component.html',
  styleUrls: ['./rooms-overview.component.scss'],
})
export class RoomsOverviewComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public floors = [-1, 0, 1, 2];
  public roomMenu = RoomMenu;
  public selectedRoom: RoomInterface;
  @Output() emitSelectedRoom: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public selectedMenu: string;
  @Input() set inputRoom(room: RoomInterface) {
    if (!room) {
      this.selectedRoom = null;
      return;
    }
    this.selectedRoom = room;
  }
  public selectRoom(room: RoomInterface): void {
    this.roomService.roomOOO = [];
    this.roomService.roomOOS = [];
    if (
      this.selectedMenu === this.roomMenu.Add ||
      this.selectedMenu === this.roomMenu.Overview
    ) {
      return;
    }

    this.emitSelectedRoom.emit(room);
    if (this.selectedMenu === this.roomMenu.OutOfOrder) {
      this.roomService.getRoomOOO(room.room_id).catch(() => {});
    }
    if (this.selectedMenu === this.roomMenu.OutOfService) {
      this.roomService.getRoomOOS(room.room_id).catch(() => {});
    }
  }

  public deselectRoom(): void {
    this.selectedRoom = null;
  }

  public deleteRoom(room: RoomInterface): void {
    this.roomService.deleteRoom(room);
  }
}
