import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './@components/@pages/home/home.component';
import { LoginComponent } from './@components/@pages/login/login.component';
import { AdminComponent } from './@components/@pages/admin/admin.component';
import { RoomsComponent } from './@components/rooms/rooms.component';
import { CalendarComponent } from './@components/@pages/calendar/calendar.component';
import { authGuard } from './@guards/auth.guard';
import { QrScannerComponent } from './@components/qr-scanner/qr-scanner.component';
import { ScannerComponent } from './@components/scan/scanner.component';
import { ServicesComponent } from './@components';

const routes: Routes = [
  {
    path: ':lang',
    children: [
      { path: 'login', component: LoginComponent },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [authGuard],
      },
      {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [authGuard],
        children: [
          {
            path: 'arrival',
            component: CalendarComponent,
          },
          {
            path: 'booking',
            component: CalendarComponent,
          },
          {
            path: 'departure',
            component: CalendarComponent,
          },
          {
            path: 'room',
            component: CalendarComponent,
          },
          {
            path: 'arrival',
            component: CalendarComponent,
          },
          {
            path: 'modify-booking',
            component: CalendarComponent,
          },
          {
            path: 'modify-rooms',
            component: CalendarComponent,
          },
        ],
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [authGuard],
        children: [
          {
            path: 'rooms',
            component: RoomsComponent,
          },
          {
            path: 'services',
            component: ServicesComponent,
          },
          { path: '**', redirectTo: 'rooms', pathMatch: 'full' },
        ],
      },
      {
        path: 'qr-scanner',
        component: QrScannerComponent,
      },
      {
        path: 'scanner',
        component: ScannerComponent,
      },
      {
        path: '**',
        redirectTo: 'login',
      },
    ],
  },
  { path: '', redirectTo: '/hu', pathMatch: 'full' },
  { path: '**', redirectTo: '/hu' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
