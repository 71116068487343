<div class="login-wrapper">
  <h1>kis portás</h1>
  <div class="form-wrapper">
    <form (submit)="signIn()" #loginForm="ngForm">
      <text-input
        [type]="'text'"
        id="username"
        name="username"
        [placeholder]="'Felhasználó név'"
        [required]="true"
        [(ngModel)]="loginGroup.username"
      ></text-input>

      <br />
      <text-input
        [type]="'password'"
        id="password"
        name="password"
        [placeholder]="'Jelszó'"
        [required]="true"
        [(ngModel)]="loginGroup.password"
      ></text-input>

      <br />
      <div class="form-textbox">
        <checkbox-input
          type="checkbox"
          [(ngModel)]="rememberMe"
          name="rememberMe"
        ></checkbox-input>
      </div>

      <br />
      <button type="submit" [disabled]="loginForm.invalid">
        <span *ngIf="!isLoading">Belépés</span>
        <app-loading-spinner
          [loading]="isLoading"
          *ngIf="isLoading"
        ></app-loading-spinner>
      </button>
    </form>
  </div>
</div>
