import { Component } from '@angular/core';
import { AuthService } from './@services/auth.service';
import { HotelService } from './@services/hotel.service';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription, filter } from 'rxjs';
import { FocusService } from './@services/focus.service';
import { BookingService } from './@services/booking.service';
import { RoomService } from './@services/room.service';
import { CalendarService } from './@services/calendar.service';
import { environment } from './@environments/environtment';
import { ServiceService } from './@services';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(
    public authService: AuthService,
    private router: Router,
    private focusService: FocusService,
    private hotelService: HotelService,
    private bookingService: BookingService,
    private calendarService: CalendarService,
    private roomService: RoomService,
    private serviceService: ServiceService
  ) {
    console.log('Env ', environment.env);

    this.hotelService
      .getHotels()
      .then(() => {
        this.authService.IsAuthenticated = true;
        this.bookingService
          .getBookings()
          .then(() => {
            this.calendarService.setCalendarDays();
            this.router.navigate(['hu/qr-scanner']);
          })
          .catch(() => {
            this.calendarService.setCalendarDays();
          });
        this.roomService.getRooms();
        this.serviceService.getServices();
      })
      .catch(() => {
        this.router.navigate(['hu/login']);
      });

    this.routerSub = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.focusService.selectedMenu = event.url.split('/')[2];
        this.focusService.selectedSubMenu = event.url.split('/')[3];
        this.routerSub.unsubscribe();
      });
  }
  title = 'KisPortas';
  private routerSub: Subscription;
}
