<div class="rooms-ooo-wrapper">
  <div class="header">
    <h1>Használaton kívül helyezés</h1>
    <p>Használaton kívül lévő, DE kiadható szobák</p>
  </div>
  <div class="hint" *ngIf="!selectedRoom">
    <span>Válassza ki a szobát</span>
  </div>

  <div class="date-list-wrapper" *ngIf="selectedRoom">
    <div class="date-card" *ngFor="let oos of roomService.roomOOS">
      <div class="date-range">
        <span class="date">{{ oos.start_date }}.</span
        ><span class="hyphen">-</span
        ><span class="date">{{ oos.end_date }}.</span>
      </div>
      <button (click)="deleteOOS(oos.oos_id)">
        <i class="delete-icon"></i>
      </button>
    </div>
    <div *ngIf="!roomService.roomOOS.length" class="hint-wrapper">
      <span>Nincsen használaton kívüli időszak</span>
    </div>
  </div>
  <div class="add-wrapper" *ngIf="selectedRoom">
    <div class="date-range">
      <date-input
        [(ngModel)]="startDate"
        name="inputoosStartDate"
        [_value]="startDate"
        label="Kezdő dátum"
      ></date-input>

      <date-input
        [(ngModel)]="endDate"
        name="inputoosEndDate"
        [_value]="endDate"
        label="Vég dátum"
      ></date-input>
    </div>
    <button
      (click)="addOOS()"
      class="primary-button"
      [disabled]="
        !startDate || !endDate || endDate?.getTime() - startDate?.getTime() < 0
      "
    >
      + Hozzáad
    </button>
  </div>
</div>
