import { Component, EventEmitter, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BookingDetailsInterface } from 'src/app/@Interfaces/booking.interface';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
} from '../../../@consts';

@Component({
  selector: 'booking-new-details',
  templateUrl: './booking-new-details.component.html',
  styleUrls: ['./booking-new-details.component.scss'],
})
export class BookingNewDetailsComponent {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    private calendarService: CalendarService,
    public roomService: RoomService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() switchToRooms = new EventEmitter<Event>();
  @Output() closeBookingDetails = new EventEmitter<void>();

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public selectedLanguage: string;
  public bookingDetails: BookingDetailsInterface = {
    channel: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    group_booking: false,
    language: '',
    nationality: '',
    source: '',
    work_related: false,
    external_id: '',
  };

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly nationalityOptions = nationalityOptions;

  public languageOptions = [{}];

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public nextStep(event?: Event): void {
    this.bookingService.saveBookingData(this.bookingDetails);
    this.calendarService.setAvailabeDayClasses();
    this.switchToRooms.emit(event);
  }

  public closeBooking(): void {
    this.bookingChanel = '';
    this.closeBookingDetails.emit();
    this.calendarService.removeAvailabeDayClasses();
    this.selectedLanguage = '';
  }

  public setCommunicationLang(value: any): void {
    this.bookingDetails.language = value?.target?.value;
  }
}
