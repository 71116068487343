import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import { ExtendedBookingRoom } from 'src/app/@Interfaces/room.interface';
import {
  BookingService,
  CalendarService,
  FocusService,
  GuestService,
  RoomService,
} from 'src/app/@services';
import { ArrivalCardComponent } from '../..';

@Component({
  selector: 'booking-arrival',
  templateUrl: './booking-arrival.component.html',
  styleUrls: ['./booking-arrival.component.scss'],
})
export class BookingArrivalComponent implements OnInit, OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private guestService: GuestService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @ViewChildren('guestForms') guestForms: QueryList<ArrivalCardComponent>;
  public _isEditing: boolean;
  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public roomsToDepart: number[] = [];
  public selectedLanguage: string;
  public selectedRoom: number;
  public rooms: {
    [key: string]: ExtendedBookingRoom;
  } = {};
  public guests: GuestInterface[] = [];
  public areFormsValid: boolean;

  ngOnInit(): void {
    this.rooms = this.bookingService.getSelectedBookingRooms(
      this.bookingService.selectedBooking.booking_id
    );

    if (this.bookingService.selectedBooking.arrived) {
      this.selectFirstArrivableRoom();
    } else {
      this.selectedRoom = Number(this.bookingService.selectedBooking.room_id);
      this.getGuests();
    }
  }

  public addAllGuests(): void {
    const guests: GuestInterface[] = [];
    this.guestForms.toArray().forEach((guestForm) => {
      if (guestForm.guestForm.valid && !guestForm.guest.guest_id) {
        guests.push(guestForm.guest);
      }
    });

    this.guestService
      .addGuests(guests, this.selectedRoom)
      .then(() => {
        this.getGuests();
      })
      .catch(() => {});
  }

  public addSingleGuest(guest: GuestInterface): void {
    this.guestService
      .addGuests([guest], this.selectedRoom)
      .then(() => {
        this.getGuests();
      })
      .catch(() => {});
  }

  public checkValidations(): void {
    this.areFormsValid = this.guestForms.toArray().every((guestForm) => {
      return guestForm.guestForm.valid;
    });
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public selectRoom(roomId: string): void {
    this.selectedRoom = Number(roomId);
    this.getGuests();
  }

  private createCards(): void {
    const neededGuests =
      this.rooms[this.selectedRoom].adults +
      this.rooms[this.selectedRoom].children;

    this.guests = [];
    for (let i = 0; i < neededGuests; i++) {
      this.guests.push({
        guest_id: '',
        name: '',
        birth_place: '',
        birth_date: '',
        tax_exempt: false,
        room_id: this.selectedRoom,
        gender: '',
        country_of_residence: '',
        postcode_of_residence: '',
        nationality: '',
      });
    }
  }

  private fillCards(guests: GuestInterface[]): void {
    let i = 0;

    guests.forEach((guest) => {
      if (guest.room_id === this.selectedRoom) {
        this.guests[i] = guest;
        i++;
      }
    });
  }

  private selectFirstArrivableRoom(): void {
    const roomIdKeys = Object.keys(this.rooms);
    for (const roomId of roomIdKeys) {
      if (!this.rooms[roomId].arrived) {
        this.selectRoom(roomId);
        return;
      }
    }
    this.selectRoom(roomIdKeys[0]);
  }

  private getGuests(): void {
    this.createCards();
    this.guestService
      .getGuests()
      .then((guests: GuestInterface[]) => {
        this.fillCards(guests);
      })
      .catch(() => {});
  }

  ngOnDestroy(): void {
    this.roomsToDepart = [];
  }
}
