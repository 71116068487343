import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ServiceInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu, ServiceMenu } from 'src/app/enums';

@Component({
  selector: 'services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.createEmptyForm();
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public form: FormGroup;
  public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public serviceGroup: any;
  public floors = [-1, 0, 1, 2];
  public selectedMenu: string = RoomMenu.Overview;
  public serviceMenu = ServiceMenu;
  public selectedService: ServiceInterface;
  public startDate: Date;
  public endDate: Date;

  public selectService(service: any): void {
    this.selectedService = service;
  }

  public deselectRoom(): void {
    this.selectedService = null;
  }

  public cancel(): void {
    this.createEmptyForm();
  }

  public selectMenu(menu: ServiceMenu): void {
    this.startDate = null;
    this.endDate = null;
    this.selectedService = null;
    this.selectedMenu = menu;
  }

  private createEmptyForm(): void {
    this.serviceGroup = this.formBuilder.group({
      name: new FormControl(),
      category: new FormControl(),
      price_huf: new FormControl(),
      vat_rate: new FormControl(),
    });
  }
}
