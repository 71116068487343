import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FocusService } from 'src/app/@services/focus.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss'],
})
export class AdminComponent implements OnInit {
  constructor(private route: Router, public focusService: FocusService) {}

  ngOnInit(): void {}

  public selectedSubPage: string = 'hotels';
  public users: any[] = [
    { name: 'Ádám', role: 'admin' },
    { name: 'Dávid', role: 'admin' },
  ];
  public rooms: any[] = [
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
    { name: 'Első szoba', type: 'apartment' },
  ];
  public roomLimit: number = 4;
  public isEditing: boolean = false;
  // public roomGroup: any = new FormGroup({
  //   roomName: new FormControl(),
  //   roomType: new FormControl(),
  //   building: new FormControl(),
  //   floor: new FormControl(),
  //   singleBeds: new FormControl(),
  //   doubleBeds: new FormControl(),
  //   extraBeds: new FormControl(),
  //   sofaBeds: new FormControl(),
  //   airConditioned: new FormControl(),
  //   accessible: new FormControl(),
  // });

  public selectSubPage(page: string) {
    console.log(page);

    this.selectedSubPage = page;
    this.route.navigate(['hu/admin/' + page]);
    this.focusService.selectedSubMenu = page;
  }

  public addNewRoom(): void {
    if (this.roomLimit === this.rooms.length) {
      return;
    }
    this.rooms.push({ name: 'new room', capacity: 2 });
  }

  // public editRoom(form: Form) {}

  public newRoom(form: any) {
    console.log(form.value);
  }

  // public focusButton(event: any) {
  //   event.srcElement.parentElement.classList.add('focused');
  //   console.log(this.roomGroup);
  // }

  public focusOutButton(event: any) {
    if (!event.srcElement.value) {
      event.srcElement.parentElement.classList.remove('focused');
    }
  }
}
