import { Component, Input, OnInit } from '@angular/core';
import { RoomInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { DateService } from 'src/app/@services/date.service';

@Component({
  selector: 'rooms-ooo',
  templateUrl: './rooms-ooo.component.html',
  styleUrls: ['./rooms-ooo.component.scss'],
})
export class RoomsOooComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private dateService: DateService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public roomLimit: number = this.hotelService.selectedHotel.room_limit;
  public dynamicThe: string = 'a(z)';
  public floors = [-1, 0, 1, 2];
  public selectedRoom: RoomInterface;
  public startDate: Date;
  public endDate: Date;

  @Input() set selectRoom(room: RoomInterface) {
    if (!room) {
      return;
    }
    this.roomService.roomOOO = [];
    this.roomService.roomOOS = [];
    this.startDate = null;
    this.endDate = null;

    this.selectedRoom = room;

    this.roomService.getRoomOOO(room.room_id).catch(() => {});
  }
  public deselectRoom(): void {
    this.selectedRoom = null;
  }

  public cancel(): void {}

  public deleteRoom(room: RoomInterface): void {
    this.roomService.deleteRoom(room);
  }

  public deleteOOO(oooId: number): void {
    this.roomService.deleteRoomOOO(oooId);
  }

  public addOOO(): void {
    const startDate = this.dateService.formatDate(this.startDate);
    const endDate = this.dateService.formatDate(this.endDate);
    const requestBody = {
      room_id: this.selectedRoom.room_id,
      start_date: startDate,
      end_date: endDate,
    };

    this.roomService.createRoomOOO(requestBody).catch(() => {});
  }
}
