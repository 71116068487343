import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotelService } from './hotel.service';
import { BookingService } from './booking.service';
import { GuestInterface } from '../@Interfaces/guest.interface';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class GuestService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private bookingService: BookingService
  ) {}

  public readQrCode: any;

  /* <-- API CALLS --> */

  public async getGuests(): Promise<GuestInterface[]> {
    return new Promise((resolve, reject) => {
      this.http
        .get<GuestInterface[]>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`
        )
        .subscribe({
          next: (guests: GuestInterface[]) => {
            console.log(guests);

            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async addGuests(
    guests: GuestInterface[],
    roomId: number
  ): Promise<GuestInterface[]> {
    const reqBody = {
      room_id: roomId,
      guests: guests,
    };
    return new Promise((resolve, reject) => {
      this.http
        .post<GuestInterface[]>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          reqBody
        )
        .subscribe({
          next: (guests: GuestInterface[]) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async editGuest(guest: GuestInterface): Promise<GuestInterface> {
    return new Promise((resolve, reject) => {
      this.http
        .put<GuestInterface>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/guests`,
          guest
        )
        .subscribe({
          next: (guests: GuestInterface) => {
            resolve(guests);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async sendScan(ids: any, scan: any): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post<any>(
          `${environment.url}api/hotels/${ids.hotelId}/bookings/${ids.bookingId}/rooms/${ids.roomId}/scan`,
          scan
        )
        .subscribe({
          next: () => {
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }
  /* <-- API CALLS --> */
}
