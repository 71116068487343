<div class="checkbox-component">
  <input
    type="checkbox"
    id="{{ name }}"
    name="{{ name }}"
    [(ngModel)]="_value"
    (ngModelChange)="sendChange()"
  />
  <label class="checkbox-label" for="{{ name }}">{{ label }}</label>
</div>
