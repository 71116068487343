import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { SidePanelTypeEnum } from 'src/app/enums';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import { TableService } from 'src/app/@services/table.service';
import { DateService } from 'src/app/@services/date.service';
import { ConfirmationDialog } from 'src/app/@dialogs/confirmation/confirmation.dialog';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'booking-room-panel',
  templateUrl: './room-panel.component.html',
  styleUrls: ['./room-panel.component.scss'],
})
export class RoomPanelComponent implements OnDestroy {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    public tableService: TableService,
    public dateService: DateService,
    private matDialog: MatDialog
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  @Input() room: any;
  @Input() isEditing: boolean;
  public selectedLanguage: string;
  public bookingChanel: string;
  public _sidePanelType: SidePanelTypeEnum;
  public _isEditing: boolean;
  public isLoading: boolean;

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public inputStartDate: any;
  public inputEndDate: any;
  public contactName: string;
  public people: number;

  public removeRoom(roomId: string): void {
    this.tableService.removeRoom(roomId);
  }

  public setRoomIdForCalendar(roomId: string): void {
    this.tableService.roomIdForCalendar = Number(roomId);
  }

  public getSelectableRoomIds(roomId: string): string[] {
    const roomIds = this.roomService.rooms.map((room) =>
      room.room_id.toString()
    );
    const bookedRoomIds = Object.keys(this.tableService.roomBookings);
    const roomIdsToLoop = roomIds.filter(
      (roomId) => !bookedRoomIds.includes(roomId.toString())
    );
    if (roomId.split('_')[0] !== 'newRoom') {
      roomIdsToLoop.push(roomId);
    }

    return roomIdsToLoop;
  }

  public selectRoom(event: any, roomId: string): void {
    const room = this.roomService.rooms.find(
      (room) => room.room_id === Number(event.target.value)
    );
    const grossPrice = room?.default_price_huf ?? 0;
    this.tableService.roomBookings[event.target.value] = {
      arrival_date: '',
      departure_date: '',
      adults: 0,
      children: 0,
      gross_price: grossPrice,
      departed: false,
      paid: false,
      arrived: false,
      room_id: event.target.value,
      contact_name: this.bookingService.bookingData.contact_name,
    };

    delete this.tableService.roomBookings[roomId];
  }

  public editRoom(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingRoom(this.bookingService.selectedBooking.booking_id, this.room)
      .then(async () => {
        this.isLoading = false;
        await this.bookingService.getBookings();
        this.tableService.roomBookings =
          this.bookingService.getSelectedBookingRooms(
            this.bookingService.selectedBooking.booking_id
          );
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public deleteRoom(): void {
    this.matDialog
      .open(ConfirmationDialog)
      .afterClosed()
      .subscribe((choice: boolean) => {
        if (!choice) return;

        this.bookingService.deleteBookingRooms(
          this.bookingService.selectedBooking.booking_id,
          this.room.key
        );
      });
  }

  ngOnDestroy(): void {
    // this.bookingService.tempBookings = [];
  }
}
