import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { floorOptions, roomTypeOptions } from 'src/app/@consts';
import { RoomInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'rooms-add',
  templateUrl: './rooms-add.component.html',
  styleUrls: ['./rooms-add.component.scss'],
})
export class RoomsAddComponent {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService
  ) {}

  public form: FormGroup;
  public roomLimit: number = this.hotelService.selectedHotel.room_limit;
  public dynamicThe: string = 'a(z)';
  public roomGroup: any;
  public floors = [-1, 0, 1, 2];
  public room: RoomInterface = {
    room_id: 0,
    name: '',
    type: '',
    floor: 0,
    building: '',
    single_beds: 0,
    double_beds: 0,
    extra_beds: 0,
    couch_beds: 0,
    max_adults: 0,
    max_family_adults: 0,
    max_family_children: 0,
    air_conditioning: false,
    wheelchair_accessible: false,
    default_price_huf: 0,
    default_price_eur: 0,
  };
  public readonly roomTypeOptions = roomTypeOptions;
  public readonly floorOptions = floorOptions;

  public newRoom(): void {
    delete this.room.room_id;
    if (!this.room.default_price_huf) {
      delete this.room.default_price_huf;
    }
    if (!this.room.default_price_eur) {
      delete this.room.default_price_eur;
    }
    this.roomService
      .newRoom(this.room)
      .then(() => {
        this.room = {
          room_id: 0,
          name: '',
          type: '',
          floor: 0,
          building: '',
          single_beds: 0,
          double_beds: 0,
          extra_beds: 0,
          couch_beds: 0,
          max_adults: 0,
          max_family_adults: 0,
          max_family_children: 0,
          air_conditioning: false,
          wheelchair_accessible: false,
          default_price_huf: 0,
          default_price_eur: 0,
        };
      })
      .catch(() => {});
  }
}
