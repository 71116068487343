import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ExtendedBookingRoom } from 'src/app/@Interfaces/room.interface';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { RoomService } from 'src/app/@services/room.service';

@Component({
  selector: 'booking-rooms-depart',
  templateUrl: './booking-rooms-depart.component.html',
  styleUrls: ['./booking-rooms-depart.component.scss'],
})
export class BookingRoomsDepartComponent implements OnInit, OnDestroy {
  constructor(
    public bookingService: BookingService,
    public calendarService: CalendarService,
    public roomService: RoomService
  ) {}

  @Output() closePanel: EventEmitter<void> = new EventEmitter<void>();
  public roomsToDepart: string[] = [];
  public isLoading: boolean;
  public rooms: {
    [key: string]: ExtendedBookingRoom;
  } = {};

  ngOnInit(): void {
    this.bookingService
      .getBookings()
      .then(() => {
        this.rooms = this.bookingService.getSelectedBookingRooms(
          this.bookingService.selectedBooking.booking_id
        );
      })
      .catch(() => {});
  }

  ngOnDestroy(): void {
    this.roomsToDepart = [];
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public cancel(): void {
    this.closePanel.emit();
    setTimeout(() => {
      this.rooms = {};
    }, 1000);
  }

  public selectToDepart(roomId: any): void {
    if (this.rooms[roomId].departed) {
      return;
    }

    const index = this.roomsToDepart.indexOf(roomId);

    if (index !== -1) {
      this.roomsToDepart.splice(index, 1);
    } else {
      this.roomsToDepart.push(roomId);
    }
  }

  public departSelectedRooms(): void {
    const roomIds = this.roomsToDepart.map((room) => Number(room));
    this.isLoading = true;
    this.bookingService
      .departRooms(
        this.bookingService.selectedBooking.booking_id,
        this.roomsToDepart
      )
      .then(() => {
        this.isLoading = false;
        this.bookingService.updateBookingRoomStatuses(
          this.bookingService.selectedBooking.booking_id,
          roomIds,
          'departed',
          true
        );
        this.rooms = this.bookingService.getSelectedBookingRooms(
          this.bookingService.selectedBooking.booking_id
        );
        this.roomsToDepart = [];
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public selectAll(): void {
    const keys = Object.keys(this.rooms);
    for (const roomKey of keys) {
      if (this.rooms[roomKey].paid) {
        this.selectToDepart(roomKey);
      }
    }
  }
}
