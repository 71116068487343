<div class="arrival-card-wrapper">
  <div *ngIf="!isScanned" class="scan-overlay">
    <h2>Scanneld be a kódot az applikációban</h2>
    <qrcode [qrdata]="qrData" [errorCorrectionLevel]="'M'"></qrcode>
  </div>
  <form (ngSubmit)="addGuest()" #guestForm="ngForm">
    <div class="guest-card">
      <div class="row-wrapper">
        <text-input
          [type]="'text'"
          [required]="true"
          [(ngModel)]="guest.last_name"
          name="last_name"
          [maxlength]="32"
          [label]="'Vezetéknév*'"
        />
        <text-input
          [type]="'text'"
          [required]="true"
          [(ngModel)]="guest.first_name"
          name="first_name"
          [maxlength]="32"
          [label]="'Keresztév*'"
        />
        <select-input
          type="dropdown"
          class="form-dropdown-select"
          name="document_type"
          [required]="true"
          [(ngModel)]="guest.document_type"
          [label]="'Okmány típusa*'"
          [options]="documentTypeOptions"
        />
        <text-input
          [type]="'text'"
          [(ngModel)]="guest.document_number"
          name="document_number"
          [maxlength]="16"
          [required]="true"
          [label]="'Dokumentum azonosító*'"
        ></text-input>
      </div>
      <div class="row-wrapper">
        <text-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.birth_last_name"
          name="birth_last_name"
          [maxlength]="32"
          [label]="'Születés vezetéknév'"
        />
        <text-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.birth_first_name"
          name="birth_first_name"
          [maxlength]="32"
          [label]="'Születés Keresztév'"
        />
        <text-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.mothers_last_name"
          name="mothers_last_name"
          [maxlength]="32"
          [label]="'Anyja vezetéknév'"
        />
        <text-input
          [type]="'text'"
          [required]="false"
          [(ngModel)]="guest.mothers_first_name"
          name="mothers_first_name"
          [maxlength]="32"
          [label]="'Anyja Keresztév'"
        />
      </div>
      <div class="row-wrapper">
        <select-input
          type="dropdown"
          class="form-dropdown-select"
          name="country_of_residence"
          [required]="true"
          [(ngModel)]="guest.country_of_residence"
          [label]="'Lakóhely ország*'"
          [options]="countryOfResidenceOptions"
        />
        <text-input
          [type]="'text'"
          [(ngModel)]="guest.postcode_of_residence"
          name="postcode_of_residence"
          [maxlength]="10"
          [required]="true"
          [label]="'Lakóhely irányítószáma*'"
        />
        <select-input
          type="dropdown"
          class="form-dropdown-select"
          name="nationality"
          [required]="true"
          [(ngModel)]="guest.nationality"
          [label]="'Nemzetiség*'"
          [options]="nationalityOptions"
        />

        <select-input
          type="dropdown"
          class="form-dropdown-select"
          name="gender"
          [required]="true"
          [(ngModel)]="guest.gender"
          [label]="'Nem*'"
          [options]="genderOptions"
        />
      </div>
      <div class="row-wrapper">
        <text-input
          [type]="'text'"
          [required]="true"
          [(ngModel)]="guest.birth_place"
          name="birth_place"
          [maxlength]="64"
          [label]="'Születési hely*'"
        />
        <date-input
          [(ngModel)]="guest.birth_date"
          name="birth_date"
          [isDateString]="true"
          [label]="'Születési dátum*'"
        />
        <checkbox-input
          type="checkbox"
          (focus)="focusService.focusButton($event)"
          (focusout)="focusService.focusOutButton($event)"
          [(ngModel)]="guest.tax_exempt"
          name="tax_exempt"
          [label]="'IFA mentes'"
        />
        <select-input
          type="dropdown"
          class="form-dropdown-select"
          name="tax_exemption_reason"
          [required]="true"
          *ngIf="guest.tax_exempt"
          [label]="'Ifa mentesség oka'"
          [(ngModel)]="guest.tax_exemption_reason"
          [options]="taxExemptionReasonOptions"
        />
        <div *ngIf="!guest.tax_exempt" class="filler"></div>
      </div>
      <div class="row-wrapper">
        <text-input
          [type]="'text'"
          [(ngModel)]="guest.visa_number"
          name="visa_number"
          [label]="'Vízum száma'"
        />
        <text-input
          [type]="'text'"
          [(ngModel)]="guest.visa_arrival_place"
          name="visa_arrival_place"
          [label]="'Beutazás helye'"
        />
        <date-input
          [(ngModel)]="guest.visa_arrival_date"
          name="visa_arrival_date"
          [isDateString]="true"
          [label]="'Beutazás dátuma'"
        />
      </div>
      <div class="button-wrapper">
        <button
          type="submit"
          *ngIf="!guest.guest_id"
          [disabled]="guestForm.invalid"
        >
          Érkeztetés
        </button>
        <button
          type="submit"
          *ngIf="guest.guest_id && !guestForm.dirty"
          disabled
        >
          Megérkezett
        </button>
        <button
          class="secondary-button"
          (click)="editGuest()"
          type="button"
          [disabled]="guestForm.invalid"
          *ngIf="guestForm.dirty && guest.guest_id"
        >
          Módosít
        </button>
        <button
          class="secondary-button"
          (click)="resetGuest()"
          type="button"
          *ngIf="guestForm.dirty && guest.guest_id"
        >
          Mégse
        </button>
      </div>
    </div>
  </form>
</div>
