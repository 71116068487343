import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { RoomInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { RoomService } from 'src/app/@services/room.service';
import { RoomMenu } from 'src/app/enums';
import { roomTypeOptions, floorOptions } from 'src/app/@consts';

@Component({
  selector: 'rooms-edit',
  templateUrl: './rooms-edit.component.html',
  styleUrls: ['./rooms-edit.component.scss'],
})
export class RoomsEditComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public roomService: RoomService,
    public focusService: FocusService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  public form: FormGroup;
  public roomLimit: number = this.hotelService.selectedHotel.room_limit;
  public dynamicThe: string = 'a(z)';

  public readonly roomTypeOptions = roomTypeOptions;
  public readonly floorOptions = floorOptions;

  public floors = [-1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  public selectedMenu: string = RoomMenu.Overview;
  public roomMenu = RoomMenu;
  public selectedRoom: RoomInterface;
  public startDate: Date;
  public endDate: Date;
  @Input() set selectRoom(room: RoomInterface) {
    if (!room) {
      return;
    }
    this.selectedRoom = room;
  }

  public deselectRoom(): void {
    this.selectedRoom = null;
  }

  public editRoom(): void {
    this.selectedRoom.floor = Number(this.selectedRoom.floor);

    const roomId = this.selectedRoom.room_id;
    delete this.selectedRoom.room_id;
    if (!this.selectedRoom.default_price_huf) {
      delete this.selectedRoom.default_price_huf;
    }
    if (!this.selectedRoom.default_price_eur) {
      delete this.selectedRoom.default_price_eur;
    }
    this.roomService
      .editRoom(this.selectedRoom, roomId.toString())
      .then(() => {
        this.selectedRoom = {
          room_id: 0,
          name: '',
          type: '',
          floor: 0,
          building: '',
          single_beds: 0,
          double_beds: 0,
          extra_beds: 0,
          couch_beds: 0,
          max_adults: 0,
          max_family_adults: 0,
          max_family_children: 0,
          air_conditioning: false,
          wheelchair_accessible: false,
          default_price_huf: 0,
          default_price_eur: 0,
        };
      })
      .catch(() => {});
  }
}
