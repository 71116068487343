<div class="date-input-wrapper">
  <span class="label">{{ label }}</span>
  <mat-form-field appearance="fill" class="m-2" subscriptSizing="dynamic">
    <input
      [disabled]="disabled"
      matInput
      [matDatepicker]="datePicker"
      name="datePicker"
      required
      [(ngModel)]="_value"
      (ngModelChange)="isDateString ? formatDate($event) : sendChange()"
      [matDatepickerFilter]="filter"
    />
    <mat-datepicker-toggle
      matIconSuffix
      [for]="datePicker"
      (click)="emitClick()"
    ></mat-datepicker-toggle>
    <mat-datepicker #datePicker></mat-datepicker>
  </mat-form-field>
</div>
