<div
  class="calendar-wrapper"
  (click)="closeSidePanel()"
  [ngClass]="{ 'active-footer': calendarService.bookingAction }"
>
  <div
    class="overlay"
    [ngClass]="{
      active: sidePanelType === 'booking'
    }"
  ></div>
  <div class="calendar-header">
    <div
      class="headers-wrapper"
      [ngClass]="{ triggered: calendarService.bookingAction }"
    >
      <div class="default-header">
        <date-input
          [(ngModel)]="calendarService.inputStartDate"
          (ngModelChange)="onDateChange()"
        ></date-input>
        <button
          class="primary-button"
          (click)="openSidePanel(sidePanelEnum.booking, $event)"
        >
          + Új Foglalás
        </button>

        <button
          class="secondary-button"
          (click)="openCloseDayDialog()"
          *ngIf="hotelService.selectedHotel"
          [disabled]="
            dateService.isDateSameAsToday(
              dateService.templateNewDate(
                hotelService.selectedHotel.last_concluded_day
              )
            )
          "
        >
          <span class="description">Napi zárás</span><br /><span class="date"
            >{{ hotelService.selectedHotel.last_concluded_day }}.</span
          >
        </button>
      </div>
      <div class="status-header">
        <div class="booking-overview" *ngIf="bookingService.selectedBooking">
          <div class="room">
            <i class="room-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .rooms
            }}</span>
          </div>
          <div class="adult">
            <i class="adult-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .adults
            }}</span>
          </div>
          <div class="child">
            <i class="child-icon"></i
            ><span>{{
              getTotalBookinData(bookingService.selectedBooking.booking_id)
                .children
            }}</span>
          </div>
        </div>
        <div class="contact" *ngIf="bookingService.selectedBooking">
          <div>
            <i class="user-icon"></i>
            <span>{{ bookingService.selectedBooking.contact_name }}</span>
          </div>
          <div>
            <i class="email-icon"></i>
            <span *ngIf="bookingService.selectedBooking.contact_email">{{
              bookingService.selectedBooking.contact_email
            }}</span>
            <span *ngIf="!bookingService.selectedBooking.contact_email">
              -</span
            >
          </div>
          <div>
            <i class="phone-icon"></i>
            <span *ngIf="bookingService.selectedBooking.contact_phone">{{
              bookingService.selectedBooking.contact_phone
            }}</span>
            <span *ngIf="!bookingService.selectedBooking.contact_phone">
              -</span
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <p (click)="getDateList()">test</p> -->
  </div>
  <div class="calendar-main">
    <calendar-table
      [sidePanelType]="sidePanelType"
      *ngIf="calendarService.dateList.length > 0"
      (openEditRooms)="openSidePanel(sidePanelEnum.modifyRooms, $event)"
    ></calendar-table>
  </div>

  <div
    class="slide-in"
    [ngClass]="{
      active: sidePanelType !== 'closed'
    }"
  >
    <booking-new-details
      *ngIf="sidePanelType === 'booking'"
      (switchToRooms)="openSidePanel(sidePanelEnum.room, $event)"
      (closeBookingDetails)="closeSidePanel()"
      (click)="$event.stopPropagation()"
    ></booking-new-details>

    <booking-edit-details
      *ngIf="sidePanelType === 'modify-booking'"
      (switchToRooms)="openSidePanel(sidePanelEnum.modifyRooms, $event)"
      (closeBookingDetails)="closeSidePanel()"
      (click)="$event.stopPropagation()"
    ></booking-edit-details>

    <booking-new-rooms
      *ngIf="sidePanelType === 'room'"
      (closePanel)="closeSidePanel()"
      (goToPayment)="openSidePanel(sidePanelEnum.payment, $event)"
      (click)="$event.stopPropagation()"
    ></booking-new-rooms>

    <booking-edit-rooms
      *ngIf="sidePanelType === 'modify-rooms'"
      (closePanel)="closeSidePanel()"
      (click)="$event.stopPropagation()"
    ></booking-edit-rooms>

    <booking-rooms-depart
      *ngIf="sidePanelType === 'departure'"
      (closePanel)="closeSidePanel()"
      (click)="$event.stopPropagation()"
    ></booking-rooms-depart>

    <booking-rooms-payment
      *ngIf="sidePanelType === 'payment'"
      (click)="$event.stopPropagation()"
      [ngStyle]="{ 'pointer-events': 'all' }"
    ></booking-rooms-payment>
    <booking-arrival
      *ngIf="sidePanelType === 'arrival'"
      (closePanel)="closeSidePanel()"
      (click)="$event.stopPropagation()"
      [ngStyle]="{ 'pointer-events': 'all' }"
    ></booking-arrival>
  </div>

  <!--room-panels -->
  <div
    class="footer"
    [ngClass]="{ active: calendarService.bookingAction }"
    (click)="$event.stopPropagation()"
  >
    <div class="button-wrapper" *ngIf="bookingService.selectedBooking">
      <button class="secondary-button" (click)="closeLowerMenu()">
        Vissza
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.modifyBooking, $event)"
        [ngClass]="{ active: sidePanelType === 'modify-booking' }"
      >
        Adatok
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.modifyRooms, $event)"
        [ngClass]="{ active: sidePanelType === 'modify-rooms' }"
      >
        Szobák
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.arrival, $event)"
        [ngClass]="{ active: sidePanelType === 'arrival' }"
      >
        Érkeztetés
      </button>

      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.payment, $event)"
        [ngClass]="{ active: sidePanelType === 'payment' }"
      >
        Fizettetés
      </button>
      <button
        class="secondary-button"
        (click)="openSidePanel(sidePanelEnum.departure, $event)"
        [ngClass]="{ active: sidePanelType === 'departure' }"
      >
        Távoztatás
      </button>
      <button class="delete-secondary-button" (click)="deleteBooking($event)">
        Foglalás törlése
      </button>
    </div>
  </div>
</div>
