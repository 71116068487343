import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotelService } from './hotel.service';
import { BookingService } from './booking.service';
import { ChargeInterface } from '../@Interfaces';
import { ChargePaymentResponse } from '../@Interfaces/charge.interface';
import { environment } from '../@environments/environtment';

@Injectable({
  providedIn: 'root',
})
export class ChargeService {
  constructor(
    private http: HttpClient,
    private hotelService: HotelService,
    private bookingService: BookingService
  ) {}

  /* <-- API CALLS --> */

  public async getCharges(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get<ChargeInterface[]>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges`
        )
        .subscribe({
          next: (charges: ChargeInterface[]) => {
            resolve(charges);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async postCharge(charge: ChargeInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges`,
          charge
        )
        .subscribe({
          next: (res: any) => {
            console.log('charge res ', res);
            resolve();
          },
          error: (err: any) => {
            console.log('charge err ', err);

            reject();
          },
        });
    });
  }

  public async editCharge(charge: ChargeInterface): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .put(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges/${charge.charge_id}`,
          charge
        )
        .subscribe({
          next: (res: any) => {
            console.log('charge res ', res);

            resolve();
          },
          error: (err: any) => {
            console.log('charge err ', err);

            reject();
          },
        });
    });
  }

  public async payCharge(charge: any): Promise<number[]> {
    return new Promise((resolve, reject) => {
      this.http
        .post<ChargePaymentResponse>(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges/pay`,
          charge
        )
        .subscribe({
          next: (res: ChargePaymentResponse) => {
            resolve(res.charges);
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public async deleteCharge(chargeId: number): Promise<void> {
    return new Promise((resolve, reject) => {
      this.http
        .delete(
          `${environment.url}api/hotels/${this.hotelService.selectedHotelId}/bookings/${this.bookingService.selectedBooking.booking_id}/charges/${chargeId}`,
          {}
        )
        .subscribe({
          next: (res: any) => {
            resolve();
          },
          error: (err: any) => {
            reject();
          },
        });
    });
  }

  /* <-- API CALLS --> */
}
