<div class="booking-details-wrapper" (click)="keepWindowOpen($event)">
  <div class="action-name clickable">
    <h1>Foglalás adatai</h1>
  </div>
  <div class="form-wrapper clickable">
    <form #bookingForm="ngForm">
      <text-input
        name="contact_name"
        [(ngModel)]="bookingDetails.contact_name"
        [required]="true"
        [label]="'Név*'"
      />

      <select-input
        id="nationality"
        name="nationality"
        [required]="true"
        [(ngModel)]="bookingDetails.nationality"
        [options]="nationalityOptions"
        (change)="setCommunicationLang($event)"
        [label]="'Nemzetiség*'"
      ></select-input>

      <select-input
        id="language"
        name="language"
        [required]="true"
        [(ngModel)]="bookingDetails.language"
        [options]="communicationLangOptions"
        [label]="'Kommunikációs nyelv*'"
      ></select-input>

      <select-input
        id="channel"
        name="channel"
        [required]="true"
        [(ngModel)]="bookingDetails.channel"
        [options]="chanelOptions"
        [label]="'Csatorna*'"
      ></select-input>

      <select-input
        *ngIf="bookingChanel === 'KOZVETITO_ONLINE'"
        id="source"
        name="source"
        [required]="true"
        [(ngModel)]="bookingDetails.source"
        [options]="sourceOptions"
        [label]="'Platform'"
      ></select-input>

      <text-input
        *ngIf="bookingChanel === 'KOZVETITO_ONLINE'"
        name="external_id"
        [(ngModel)]="bookingDetails.external_id"
        [type]="'text'"
        [label]="'id'"
      />

      <text-input
        name="contact_email"
        [(ngModel)]="bookingDetails.contact_email"
        [type]="'email'"
        [label]="'Email'"
      />

      <text-input
        name="contact_phone"
        [(ngModel)]="bookingDetails.contact_phone"
        [type]="'text'"
        [label]="'Telefon'"
      />

      <div class="checkbox-wrapper">
        <checkbox-input
          id="group"
          name="group_booking"
          [label]="'Csoportos'"
          [(ngModel)]="bookingDetails.group_booking"
        ></checkbox-input>

        <checkbox-input
          id="business"
          name="work_related"
          [label]="'Üzleti'"
          [(ngModel)]="bookingDetails.work_related"
        ></checkbox-input>
      </div>
    </form>
  </div>
  <div class="button-wrapper clickable">
    <button class="secondary-button" (click)="setBackOldForm()" type="button">
      Mégse
    </button>

    <button
      class="primary-button"
      type="submit"
      [disabled]="
        bookingForm.invalid ||
        areObjectsEqual(bookingForm.value, bookingService.bookingData)
      "
      (click)="editBookingDetails()"
    >
      <span *ngIf="!isLoading">Módosítás </span>
      <app-loading-spinner
        [loading]="isLoading"
        *ngIf="isLoading"
      ></app-loading-spinner>
    </button>
  </div>
</div>
