<div class="scanner-wrapper">
  <video #video autoplay (playing)="drawOverlay()"></video>
  <canvas #canvas></canvas>
  <img [src]="src" alt="'no src'" />
  <ngx-scanner-text
    #scanner="scanner"
    [configs]="scannerConfig"
    (event)="onData($event)"
  ></ngx-scanner-text>
  <div
    class="switch-button"
    (click)="switchNextVideo()"
    *ngIf="videoDevices.length > 1"
  ></div>
  <button (click)="captureFrame()" class="capture-button">Scan</button>
</div>
