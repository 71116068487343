import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, catchError, map, Observable, of } from 'rxjs';
import { environment } from '../@environments/environtment';
import { LoginResInterface } from '../@Interfaces';
import { PermissionInterface } from '../@Interfaces/auth.interface';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private router: Router, private http: HttpClient) {}

  public IsAuthenticated: boolean;
  public isPremium: boolean;
  public roles: { [key: number]: PermissionInterface } = {};
  private tokenSubject = new BehaviorSubject<string | null>(null);

  public async signIn(body: any): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.http
        .post<LoginResInterface>(`${environment.url}api/auth`, body)
        .subscribe({
          next: (res: LoginResInterface) => {
            this.IsAuthenticated = true;
            if (Capacitor.isNativePlatform())
              this.router.navigate(['hu/qr-scanner']);
            else this.router.navigate(['hu/home']);

            res.permissions.forEach((group) => {
              this.roles[group.hotel_id] = group;
            });
            resolve();
          },
          error: () => {
            reject();
          },
        });
    });
  }

  public setToken(token: string | null): void {
    this.tokenSubject.next(token);
  }

  public getToken(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }

  public isAuthenticatedAsync(): Observable<boolean> {
    return this.http.post<any>(`${environment.url}api/auth/refresh`, {}).pipe(
      map(() => true),
      catchError(() => {
        return of(false);
      })
    );
  }
}
