import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ServiceInterface } from 'src/app/@Interfaces';
import { ServiceService } from 'src/app/@services';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { ServiceMenu } from 'src/app/enums';

@Component({
  selector: 'services-overview',
  templateUrl: './services-overview.component.html',
  styleUrls: ['./services-overview.component.scss'],
})
export class ServicesOverviewComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public serviceService: ServiceService,
    public focusService: FocusService
  ) {}

  ngOnInit(): void {
    if (this.hotelService.selectedHotel) {
      const firstLetter = this.hotelService.selectedHotel.name.toLowerCase();
      const vowels = ['a', 'e', 'i', 'o', 'u'];
      this.dynamicThe = vowels.includes(firstLetter) ? 'az' : 'a';
    }
  }
  // public roomLimit: number;
  public dynamicThe: string = 'a(z)';
  public serviceMenu = ServiceMenu;
  public selectedService: ServiceInterface;
  @Output() emitSelectedService: EventEmitter<any> = new EventEmitter<any>();
  @Input()
  public selectedMenu: string;
  @Input() set inputService(service: ServiceInterface) {
    if (!service) {
      this.selectedService = null;
      return;
    }
    this.selectedService = service;
  }
  public selectService(service: ServiceInterface): void {
    if (
      this.selectedMenu === this.serviceMenu.Add ||
      this.selectedMenu === this.serviceMenu.Overview
    ) {
      return;
    }

    this.emitSelectedService.emit(service);
  }

  public deselectService(): void {
    this.selectedService = null;
  }
}
