import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { BookingDetailsInterface } from 'src/app/@Interfaces/booking.interface';
import { BookingService } from 'src/app/@services/booking.service';
import { CalendarService } from 'src/app/@services/calendar.service';
import { FocusService } from 'src/app/@services/focus.service';
import { RoomService } from 'src/app/@services/room.service';
import {
  chanelOptions,
  communicationLangOptions,
  nationalityOptions,
  sourceOptions,
} from '../../../@consts';

@Component({
  selector: 'booking-edit-details',
  templateUrl: './booking-edit-details.component.html',
  styleUrls: ['./booking-edit-details.component.scss'],
})
export class BookingEditDetailsComponent implements OnInit {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    private calendarService: CalendarService,
    public roomService: RoomService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Output() switchToRooms = new EventEmitter<Event>();
  @Output() closeBookingDetails = new EventEmitter<void>();

  public bookingChanel: string;
  public contactName: string;
  public inputEndDate: any;
  public inputStartDate: any;
  public isLoading: boolean;
  public people: number;
  public selectedLanguage: string;

  public bookingDetails: BookingDetailsInterface = {
    channel: '',
    contact_email: '',
    contact_name: '',
    contact_phone: '',
    group_booking: false,
    language: '',
    nationality: '',
    source: '',
    work_related: false,
    external_id: '',
  };

  public readonly chanelOptions = chanelOptions;
  public readonly sourceOptions = sourceOptions;
  public readonly communicationLangOptions = communicationLangOptions;
  public readonly nationalityOptions = nationalityOptions;

  ngOnInit(): void {
    this.bookingDetails = {
      channel: this.bookingService.selectedBooking.channel,
      contact_email: this.bookingService.selectedBooking.contact_email,
      contact_name: this.bookingService.selectedBooking.contact_name,
      contact_phone: this.bookingService.selectedBooking.contact_phone,
      group_booking: this.bookingService.selectedBooking.group_booking,
      language: this.bookingService.selectedBooking.language,
      source: this.bookingService.selectedBooking.source,
      work_related: this.bookingService.selectedBooking.work_related,
      external_id: this.bookingService.selectedBooking.external_id,
      nationality: this.bookingService.selectedBooking.nationality,
    };
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public editBookingDetails(): void {
    this.isLoading = true;
    this.bookingService
      .putBookingDetails(
        this.bookingService.selectedBooking.booking_id,
        this.bookingDetails
      )
      .then(() => {
        this.isLoading = false;
        this.bookingService.saveBookingData(this.bookingDetails);
        this.bookingService.getBookings().catch(() => {});
        /* this.bookingService.updateBookingsData(
          this.calendarService.selectedBooking.booking_id
        ); */
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public setCommunicationLang(event: any): void {
    this.selectedLanguage = event.target.value;
  }

  public setBackOldForm(): void {
    this.bookingDetails = {
      channel: '',
      contact_email: '',
      contact_name: '',
      contact_phone: '',
      group_booking: false,
      language: '',
      nationality: '',
      source: '',
      work_related: false,
      external_id: '',
    };
  }

  public areObjectsEqual(obj1: any, obj2: any): boolean {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    // Check if the number of keys is the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    // Check if values for each key are the same
    for (const key of keys1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }

    // If all checks pass, the objects are equal
    return true;
  }
}
