import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { GuestInterface } from 'src/app/@Interfaces/guest.interface';
import {
  BookingService,
  CalendarService,
  DateService,
  FocusService,
  GuestService,
  HotelService,
  RoomService,
} from 'src/app/@services';
import {
  countryOfResidenceOptions,
  documentTypeOptions,
  genderOptions,
  nationalityOptions,
  taxExemptionReasonOptions,
} from 'src/app/@consts';
import * as MRZ from 'mrz';

@Component({
  selector: 'arrival-card',
  templateUrl: './arrival-card.component.html',
  styleUrls: ['./arrival-card.component.scss'],
})
export class ArrivalCardComponent implements OnInit, AfterViewInit {
  constructor(
    private dateAdapter: DateAdapter<Date>,
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private dateService: DateService,
    private guestService: GuestService,
    private hotelService: HotelService
  ) {
    this.dateAdapter.getFirstDayOfWeek = () => {
      return 1;
    };
  }

  @Input() guest: GuestInterface;
  @Input() roomId: number;
  @ViewChild('guestForm', { static: false }) guestForm: NgForm;
  @Output('isValid') isValid = new EventEmitter<void>();
  @Output('addGuest') addGuestEmmiter = new EventEmitter<GuestInterface>();
  public isLoading: boolean;
  public isScannerOpen: boolean;
  public isScanned: false; // TODO: dynamic
  public documentType: 'id' | 'passport' = 'id';
  public readonly genderOptions = genderOptions;
  public readonly documentTypeOptions = documentTypeOptions;
  public readonly countryOfResidenceOptions = countryOfResidenceOptions;
  public readonly nationalityOptions = nationalityOptions;
  public readonly taxExemptionReasonOptions = taxExemptionReasonOptions;

  private originalFormVal: string;
  public qrData: any;

  ngOnInit(): void {
    this.qrData = JSON.stringify({
      hotelId: this.hotelService.selectedHotelId,
      bookingId: this.bookingService.selectedBooking.booking_id,
      roomId: this.roomId,
    });
  }

  ngAfterViewInit(): void {
    this.guestForm.valueChanges.subscribe(() => {
      this.isValid.emit();
    });
    this.guest.tax_exemption_reason = null;

    this.originalFormVal = JSON.stringify(this.guest);
  }

  public addGuest(): void {
    this.addGuestEmmiter.emit(this.guest);
    /* this.isLoading = true;
    this.guestService
      .addGuests([this.guest], this.roomId)
      .then((guests: GuestInterface[]) => {
        this.guest = guests[0];
        this.originalFormVal = JSON.stringify(this.guest);
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      }); */
  }

  public editGuest(): void {
    this.guestService
      .editGuest(this.guest)
      .then((editedGuest: GuestInterface) => {
        this.guest = editedGuest;
        this.originalFormVal = JSON.stringify(editedGuest);
        this.guestForm.form.markAsPristine();
      })
      .catch(() => {});
  }

  public resetGuest(): void {
    this.guest = JSON.parse(this.originalFormVal);
    this.guestForm.form.markAsPristine();
  }

  public formatDate(charge: GuestInterface, date: Date): void {
    charge.birth_date = this.dateService.formatDate(date);
  }

  public handleScanResult(scanRes: MRZ.ParseResult): void {
    console.log('scan result ', scanRes);
    this.fillScanResult(scanRes);
    this.closeScanner();
  }

  public openScanner(documentType: 'id' | 'passport'): void {
    this.documentType = documentType;
    this.isScannerOpen = true;
  }

  public closeScanner(): void {
    this.isScannerOpen = false;
  }

  private fillScanResult(scanRes: MRZ.ParseResult): void {
    //GUEST FIELDS
    this.guest.birth_date = scanRes.fields.birthDate ?? this.guest.birth_date;
    this.guest.first_name = scanRes.fields.firstName ?? this.guest.first_name;
    this.guest.last_name = scanRes.fields.lastName ?? this.guest.last_name;
    this.guest.nationality =
      scanRes.fields.nationality ?? this.guest.nationality;
    this.guest.document_number =
      scanRes.fields.documentNumber ?? this.guest.document_number;
    /* this.guest.document_type =
     this.documentType === 'id' ? 'I': 'P' */
    this.guest.gender = scanRes.fields.sex ?? this.guest.gender;

    //GUEST OG FIELDS
    this.guest.birth_date_og = scanRes.fields.birthDate ?? null;
    this.guest.document_number_og = scanRes.fields.documentNumber ?? null;
    this.guest.document_type_og = this.documentType === 'id' ? 'I' : 'P';
    this.guest.first_name_og = scanRes.fields.firstName ?? null;
    this.guest.last_name_og = scanRes.fields.lastName ?? null;
    this.guest.gender_og = scanRes.fields.sex ?? null;
    this.guest.nationality_og = scanRes.fields.nationality ?? null;

    //GUEST OG ALWAYS NULL
    this.guest.birth_first_name_og = null;
    this.guest.birth_last_name_og = null;
    this.guest.birth_place_og = null;
    this.guest.mothers_first_name_og = null;
    this.guest.mothers_last_name_og = null;

    //temp
    this.guest.birth_first_name = null;
    this.guest.birth_last_name = null;
    this.guest.mothers_first_name = null;
    this.guest.mothers_last_name = null;
    this.guest.visa_number = null;
    this.guest.visa_arrival_date = null;
    this.guest.visa_arrival_place = null;

    const scanData = {
      birth_date: this.guest.birth_date_og,
      document_number: this.guest.document_number_og,
      document_type: this.guest.document_type_og,
      first_name: this.guest.first_name_og,
      last_name: this.guest.last_name_og,
      gender: this.guest.gender_og,
      nationality: this.guest.nationality_og,
    };

    //this.guestService.sendScan(scanData);
  }
}
