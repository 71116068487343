<div class="room-panel">
  <div class="room-name">
    <span *ngIf="!tableService.roomBookings[room.key].newRoom">{{
      roomService.getRoomName(room.key)
    }}</span>
    <select
      class="form-dropdown-select"
      *ngIf="tableService.roomBookings[room.key].newRoom"
      (change)="selectRoom($event, room.key)"
    >
      <option style="display: none"></option>
      <option
        *ngFor="let roomId of getSelectableRoomIds(room.key)"
        [value]="roomId"
        [selected]="roomId === room.key"
      >
        {{ roomService.getRoomName(roomId) }}
      </option>
    </select>
  </div>

  <div class="status">
    <span>{{
      tableService.roomBookings[room.key] | bookingStatus : "hu"
    }}</span>
  </div>

  <div class="delete">
    <button type="button" (click)="deleteRoom()">
      <mat-icon>delete_forever</mat-icon>
    </button>
  </div>
  <div class="modify-wrapper">
    <button class="secondary-button" *ngIf="isEditing" (click)="editRoom()">
      Módosít
    </button>
  </div>

  <div class="date-wrapper">
    <date-input
      name="inputStartDate"
      [(ngModel)]="tableService.roomBookings[room.key].arrival_date"
      (ngModelChange)="
        tableService.handleDatePickerV2(
          room.key,
          tableService.roomBookings[room.key].arrival_date,
          true
        )
      "
      [disabled]="
        room.key.split('_')[0] === 'newRoom' ||
        tableService.roomBookings[room.key].arrived
      "
      [filter]="tableService.getBookedDayFilter"
      (clicked)="setRoomIdForCalendar(room.key)"
    ></date-input>
    <span class="num-of-nights"
      >{{
        calendarService.calculateDaysDifference(
          tableService.roomBookings[room.key].arrival_date,
          tableService.roomBookings[room.key].departure_date
        )
      }}
      éj</span
    >

    <date-input
      name="inputDepartureDate"
      [(ngModel)]="tableService.roomBookings[room.key].departure_date"
      (ngModelChange)="
        tableService.handleDatePickerV2(
          room.key,
          tableService.roomBookings[room.key].departure_date,
          false
        )
      "
      [disabled]="
        room.key.split('_')[0] === 'newRoom' ||
        tableService.roomBookings[room.key].departed
      "
      [filter]="tableService.getBookedDayFilter"
      (clicked)="setRoomIdForCalendar(room.key)"
    ></date-input>
  </div>

  <div class="details">
    <div class="occupancy-wrapper">
      <div class="occupancy-block">
        <input
          [disabled]="
            room.key.split('_')[0] === 'newRoom' ||
            tableService.roomBookings[room.key].arrived
          "
          type="number"
          [(ngModel)]="tableService.roomBookings[room.key].adults"
        />
        <div>
          <img class="adult-img" src="assets/adult.svg" />
        </div>
      </div>
      <div class="occupancy-block">
        <input
          [disabled]="
            room.key.split('_')[0] === 'newRoom' ||
            tableService.roomBookings[room.key].arrived
          "
          type="number"
          [(ngModel)]="tableService.roomBookings[room.key].children"
        />
        <div>
          <img class="child-img" src="assets/child.svg" />
        </div>
      </div>
    </div>
    <div class="amount-block">
      <input
        [disabled]="
          room.key.split('_')[0] === 'newRoom' ||
          tableService.roomBookings[room.key].paid
        "
        class="amount"
        type="number"
        [(ngModel)]="tableService.roomBookings[room.key].gross_price"
      />
      <input type="text" class="huf-label" value="HUF" disabled />
    </div>
  </div>
  <!-- <div class="pre-payment-wrapper">
    <input
      type="checkbox"
      [(ngModel)]="tableService.roomBookings[room.key].ifa_included"
    />
    <input
      type="number"
      [(ngModel)]="tableService.roomBookings[room.key].prepayment"
    />
  </div> -->
</div>
