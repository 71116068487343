<div class="room-edit-wrapper">
  <div class="header">
    <h1>Módosítás</h1>
    <p>Itt módosíthatja a szobák adatait</p>
  </div>
  <div class="hint" *ngIf="!selectedRoom">
    <span>Válassza ki a módosítani kívánt szobát</span>
  </div>
  <div class="form-wrapper" [hidden]="!selectedRoom">
    <form #roomForm="ngForm">
      <ng-container *ngIf="selectedRoom">
        <text-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="selectedRoom.name"
          [label]="'Szoba neve*'"
        />
        <select-input
          id="type"
          name="type"
          [required]="true"
          [(ngModel)]="selectedRoom.type"
          [options]="roomTypeOptions"
          [label]="'Szoba típusa*'"
        />
        <text-input
          [type]="'text'"
          id="building"
          name="building"
          [required]="true"
          [(ngModel)]="selectedRoom.building"
          [label]="'Épület* (pl.: Főépület)'"
        />
        <select-input
          id="floor"
          name="floor"
          [required]="true"
          [(ngModel)]="selectedRoom.floor"
          [options]="floorOptions"
          [label]="'Emelet*'"
        />
        <text-input
          [type]="'number'"
          id="single_beds"
          name="single_beds"
          [required]="true"
          [(ngModel)]="selectedRoom.single_beds"
          [label]="'Szimpla ágyak száma*'"
        />
        <text-input
          [type]="'number'"
          id="double_beds"
          name="double_beds"
          [required]="true"
          [(ngModel)]="selectedRoom.double_beds"
          [label]="'Franciaágyak száma*'"
        />
        <text-input
          [type]="'number'"
          id="extra_beds"
          name="extra_beds"
          [required]="true"
          [(ngModel)]="selectedRoom.extra_beds"
          [label]="'Pótágyak száma*'"
        />
        <text-input
          [type]="'number'"
          id="couch_beds"
          name="couch_beds"
          [required]="true"
          [(ngModel)]="selectedRoom.couch_beds"
          [label]="'Fotelágyak száma*'"
        />
        <text-input
          [type]="'number'"
          id="max_adults"
          name="max_adults"
          [required]="true"
          [(ngModel)]="selectedRoom.max_adults"
          [label]="'Max felnőttek száma*'"
        />
        <text-input
          [type]="'number'"
          id="max_family_adults"
          name="max_family_adults"
          [required]="true"
          [(ngModel)]="selectedRoom.max_family_adults"
          [label]="'Max családos felnőttek száma*'"
        />
        <text-input
          [type]="'number'"
          id="max_family_children"
          name="max_family_children"
          [required]="true"
          [(ngModel)]="selectedRoom.max_family_children"
          [label]="'Max családos gyerekek száma*'"
        />
        <div class="row-wrapper">
          <text-input
            [type]="'number'"
            id="default_price_huf"
            name="default_price_huf"
            [required]="true"
            [(ngModel)]="selectedRoom.default_price_huf"
            [label]="'Szobaár HUF*'"
          />
          <checkbox-input
            id="ifa_included"
            name="ifa_included"
            [name]="'ifa_included'"
            [label]="'tartalmazza az IFÁ-t'"
            [(ngModel)]="selectedRoom.ifa_included"
          />
        </div>
        <checkbox-input
          id="air_conditioning"
          name="air_conditioning"
          [name]="'air_conditioning'"
          [label]="'Légkondícionált'"
          [(ngModel)]="selectedRoom.air_conditioning"
        />
        <checkbox-input
          id="wheelchair_accessible"
          name="wheelchair_accessible"
          [name]="'wheelchair_accessible'"
          [label]="'Mozgássérült barát'"
          [(ngModel)]="selectedRoom.wheelchair_accessible"
        />
      </ng-container>
    </form>
  </div>

  <div class="button-wrapper" *ngIf="selectedRoom">
    <button class="secondary-button" type="button" (click)="deselectRoom()">
      Mégse
    </button>
    <button
      class="primary-button"
      type="submit"
      [disabled]="roomForm?.invalid"
      (click)="editRoom()"
    >
      Módosít
    </button>
  </div>
</div>
