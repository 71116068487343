import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ServiceInterface } from 'src/app/@Interfaces';
import { FocusService } from 'src/app/@services/focus.service';
import { HotelService } from 'src/app/@services/hotel.service';
import { ServiceMenu } from 'src/app/enums';
import { vatRateOptions, serviceCategoryOptions } from 'src/app/@consts';
import { ServiceService } from 'src/app/@services';

@Component({
  selector: 'services-edit',
  templateUrl: './services-edit.component.html',
  styleUrls: ['./services-edit.component.scss'],
})
export class ServicesEditComponent implements OnInit {
  constructor(
    public hotelService: HotelService,
    public serviceService: ServiceService,
    public focusService: FocusService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {}
  public form: FormGroup;

  public readonly vatRateOptions = vatRateOptions;
  public readonly serviceCategoryOptions = serviceCategoryOptions;

  public selectedMenu: string = ServiceMenu.Overview;
  public serviceMenu = ServiceMenu;
  public selectedService: ServiceInterface;
  @Input() set selectService(service: ServiceInterface) {
    if (!service) {
      return;
    }
    this.selectedService = service;
  }

  public deselectService(): void {
    this.selectedService = null;
  }

  public editService(): void {
    const serviceId = this.selectedService.service_id;

    this.serviceService
      .editService(this.selectedService, serviceId.toString())
      .then(() => {
        this.selectedService = {
          service_id: 0,
          name: '',
          category: '',
          price_huf: 0,
          vat_rate: 0,
        };
      })
      .catch(() => {});
  }
}
