<div class="room-edit-wrapper">
  <div class="header">
    <h1>Módosítás</h1>
    <p>Itt módosíthatja a szolgáltatásokat</p>
  </div>
  <div class="hint" *ngIf="!selectedService">
    <span>Válassza ki a módosítani kívánt szolgáltatást</span>
  </div>
  <div class="form-wrapper" [hidden]="!selectedService">
    <form #serviceForm="ngForm">
      <ng-container *ngIf="selectedService">
        <text-input
          [type]="'text'"
          id="name"
          name="name"
          [required]="true"
          [(ngModel)]="selectedService.name"
          [label]="'Szolgáltatás neve*'"
        />
        <text-input
          [type]="'number'"
          id="price_huf"
          name="price_huf"
          [required]="true"
          [(ngModel)]="selectedService.price_huf"
          [label]="'Bruttó ár HUF*'"
        />
        <select-input
          id="category"
          name="category"
          [required]="true"
          [(ngModel)]="selectedService.category"
          [label]="'Kategória*'"
          [options]="serviceCategoryOptions"
        />
        <select-input
          id="vat_rate"
          name="vat_rate"
          [required]="true"
          [(ngModel)]="selectedService.vat_rate"
          [label]="'ÁFA*'"
          [options]="vatRateOptions"
        />
      </ng-container>
    </form>
  </div>

  <div class="button-wrapper" *ngIf="selectedService">
    <button class="secondary-button" type="button" (click)="deselectService()">
      Mégse
    </button>
    <button class="primary-button" type="submit" (click)="editService()">
      Módosít
    </button>
  </div>
</div>
