export enum SidePanelTypeEnum {
  arrival = 'arrival',
  booking = 'booking',
  closed = 'closed',
  departure = 'departure',
  payment = 'payment',
  room = 'room',
  modifyBooking = 'modify-booking',
  modifyRooms = 'modify-rooms',
}

export enum RoomMenu {
  Overview = 'OVERVIEW',
  Add = 'ADD',
  Edit = 'EDIT',
  OutOfOrder = 'OUT_OF_ORDER',
  OutOfService = 'OUT_OF_SERVICE',
}

export enum ServiceMenu {
  Overview = 'OVERVIEW',
  Add = 'ADD',
  Edit = 'EDIT',
}

export enum ChargeTypes {
  ifa = 'IFA',
  room = 'ROOM',
  prepayment = 'PREPAYMENT',
}
