import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ChargeInterface } from 'src/app/@Interfaces';
import { ExtendedBookingRoom } from 'src/app/@Interfaces/room.interface';
import {
  BookingService,
  CalendarService,
  ChargeService,
  DateService,
  FocusService,
  RoomService,
} from 'src/app/@services';
import { ChargeTypes } from 'src/app/enums';
import { paymentOptions } from '../../../@consts';

@Component({
  selector: 'booking-rooms-payment',
  templateUrl: './booking-rooms-payment.component.html',
  styleUrls: ['./booking-rooms-payment.component.scss'],
})
export class BookingRoomsPaymentComponent implements OnInit {
  constructor(
    public bookingService: BookingService,
    public focusService: FocusService,
    public calendarService: CalendarService,
    public roomService: RoomService,
    private chargeService: ChargeService,
    public dateService: DateService
  ) {}

  public readonly paymentOptions: any = paymentOptions;
  public isLoading: boolean;
  public rooms: {
    [key: string]: ExtendedBookingRoom;
  } = {};
  public charges: ChargeInterface[];
  private originalCharges: ChargeInterface[];
  public totalPrice: number;
  public paidAmount: number;
  public remainingAmount: number;
  public selectedRoom: number;
  public areAllRoomsSelected: boolean;
  public chargeTypes = ChargeTypes;

  @ViewChildren('chargeForms') chargeForms: QueryList<NgForm>;

  ngOnInit(): void {
    this.rooms = this.bookingService.getSelectedBookingRooms(
      this.bookingService.selectedBooking.booking_id
    );
    this.getCharges();
    this.selectFirstArrivableRoom();
  }

  public keepWindowOpen(event: Event): void {
    event.stopPropagation();
  }

  public addCharge(): void {
    this.charges.push({
      booking_id: this.bookingService.selectedBooking.booking_id,
      room_id: this.bookingService.selectedBooking.room_id,
      charge_type: null,
      gross_price: 0,
      qty: 0,
      unit: '',
      totalPrice: 0,
      vat_rate: 0,
      payment_date: '',
      payment_method: '',
      paid: false,
      paid_by: '',
      company_payment: 0,
      name: '',
      comment: '',
    });
  }

  public getCharges(): void {
    this.chargeService
      .getCharges()
      .then((charges: ChargeInterface[]) => {
        this.charges = charges;

        this.originalCharges = JSON.parse(JSON.stringify(charges));
        this.setChargeSummary();
      })
      .catch(() => {});
  }

  public selectRoom(roomId: string): void {
    this.selectedRoom = Number(roomId);
    this.setChargeSummary();
  }

  public async payRoom(roomId: string): Promise<void> {
    this.isLoading = true;
    this.bookingService
      .payRoom(this.bookingService.selectedBooking.booking_id, {
        rooms: [
          {
            room_id: Number(roomId),
            gross_price: this.rooms[roomId].gross_price,
          },
        ],
      })
      .then(async () => {
        this.isLoading = false;
        await this.bookingService.getBookings().catch(() => {});
        this.rooms = this.bookingService.getSelectedBookingRooms(
          this.bookingService.selectedBooking.booking_id
        );
      })
      .catch(() => {
        this.isLoading = false;
      });
  }

  public formatDate(charge: ChargeInterface, date: Date): void {
    charge.payment_date = this.dateService.formatDate(date);
  }

  public postCharge(charge: ChargeInterface): void {
    charge.room_id = this.selectedRoom;
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .postCharge(charge)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public editCharge(charge: ChargeInterface): void {
    charge.vat_rate = Number(charge.vat_rate);
    this.chargeService
      .editCharge(charge)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public payCharge(payingCharge: ChargeInterface): void {
    const requestBody = {
      charges: [
        {
          charge_id: payingCharge.charge_id,
          payment_method: payingCharge.payment_method,
          payment_date: payingCharge.payment_date,
        },
      ],
    };
    this.chargeService
      .payCharge(requestBody)
      .then((paidCharges: number[]) => {
        this.charges.map((charge) => {
          if (paidCharges.includes(charge.charge_id)) {
            charge.paid = true;
          }
          return charge;
        });
      })
      .catch(() => {});
  }

  public deleteCharge(charge: ChargeInterface): void {
    this.chargeService
      .deleteCharge(charge.charge_id)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public setVatIfKnown(charge: ChargeInterface): void {
    if (charge.charge_type === this.chargeTypes.prepayment) {
      charge.vat_rate = 5;
    }
  }

  public payAllCharges(form: {
    group_payment_method: string;
    group_payment_date: Date;
  }): void {
    const requestBody: { charges: any[] } = { charges: [] };
    this.charges.forEach((charge) => {
      if (
        (this.areAllRoomsSelected || charge.room_id === this.selectedRoom) &&
        !charge.paid
      ) {
        requestBody.charges.push({
          charge_id: charge.charge_id,
          payment_method: form.group_payment_method,
          payment_date: this.dateService.formatDate(form.group_payment_date),
        });
      }
    });
    this.chargeService
      .payCharge(requestBody)
      .then(() => {
        this.getCharges();
      })
      .catch(() => {});
  }

  public isModified(index: number): boolean {
    return (
      JSON.stringify(this.charges[index]) ===
      JSON.stringify(this.chargeForms.toArray()[index].form.value)
    );
  }

  public selectAllRooms(): void {
    this.areAllRoomsSelected = true;
    this.setChargeSummary();
  }

  public deselectAllRooms(): void {
    this.areAllRoomsSelected = false;
    this.setChargeSummary();
  }

  public resetChargeForm(index: number): void {
    this.charges[index] = JSON.parse(
      JSON.stringify(this.originalCharges[index])
    );
  }

  public remainingAmountForRoom(roomId: string): number {
    let remainingAmount: number = 0;
    if (!this.charges) return remainingAmount;

    this.charges.forEach((charge: ChargeInterface) => {
      if (Number(roomId) !== charge.room_id || charge.paid) {
        return;
      }

      remainingAmount += charge.gross_price * charge.qty;
    });
    return remainingAmount;
  }

  public setAllPaymentMethod(paymentMethod: any): void {
    this.charges = this.charges.map((charge: ChargeInterface) => {
      charge.payment_method = paymentMethod;
      return charge;
    });
  }

  public setAllPaymentDate(paymentDate: any): void {
    this.charges = this.charges.map((charge: ChargeInterface) => {
      charge.payment_date = paymentDate;
      return charge;
    });
  }

  private setChargeSummary(): void {
    this.totalPrice = 0;
    this.paidAmount = 0;
    this.remainingAmount = 0;
    this.charges.forEach((charge: ChargeInterface) => {
      if (this.selectedRoom !== charge.room_id && !this.areAllRoomsSelected) {
        return;
      }
      this.totalPrice += charge.gross_price * charge.qty;
      if (charge.paid) {
        this.paidAmount += charge.gross_price * charge.qty;
      } else {
        this.remainingAmount += charge.gross_price * charge.qty;
      }
    });
  }

  private selectFirstArrivableRoom(): void {
    const roomIdKeys = Object.keys(this.rooms);
    for (const roomId of roomIdKeys) {
      if (!this.rooms[roomId].paid) {
        this.selectedRoom = Number(roomId);
        return;
      }
    }
    this.selectedRoom = Number(roomIdKeys[0]);
  }
}
