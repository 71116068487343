export const chanelOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az értékesítési csatornát',
  },
  { value: 'DIREKT_ONLINE', label: 'Direkt-online' },
  { value: 'DIREKT_HAGYOMANYOS', label: 'Direkt-hagyományos' },
  { value: 'KOZVETITO_ONLINE', label: 'Közvetítő-online' },
  { value: 'KOZVETITO_HAGYOMANYOS', label: 'Közvetítő-hagyományos' },
];

export const paymentOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Fizetési mód',
  },
  { value: 'ATUTALAS', label: 'Átutalás' },
  { value: 'BANKKARTYA', label: 'Bankkártya' },
  { value: 'KESZPENZ', label: 'Készpénz' },
  { value: 'SZEP_KARTYA', label: 'Szépkártya' },
  { value: 'UTALVANY', label: 'Utalvány' },
  { value: 'EGYEB', label: 'Egyéb' },
];

export const sourceOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a platformot',
  },
  { value: 'aribnb', label: 'Airbnb' },
  { value: 'booking.com', label: 'booking.com' },
  { value: 'hotels.com', label: 'hotels.com' },
  { value: 'szallas.hu', label: 'szallas.hu' },
  { value: 'other', label: 'Egyéb' },
];

export const communicationLangOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a preferált Kommunikációs nyelvet',
  },
  { value: 'HU', label: 'Magyar' },
  { value: 'PL', label: 'Lengyel' },
  { value: 'DE', label: 'Német' },
  { value: 'IT', label: 'Olasz' },
  { value: 'GR', label: 'Görög' },
  { value: 'GR', label: 'Görög' },
  { value: 'SE', label: 'Svéd' },
];

export const nationalityOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a nemzetiségét',
  },
  { value: 'HU', label: 'Magyar' },
  { value: 'PL', label: 'Lengyel' },
  { value: 'DE', label: 'Német' },
  { value: 'IT', label: 'Olasz' },
  { value: 'GB', label: 'Angol' },
  { value: 'GR', label: 'Görög' },
  { value: 'GR', label: 'Görög' },
  { value: 'SE', label: 'Svéd' },
];

export const roomTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a szoba típusát',
  },
  { value: 'APARTMAN', label: 'Apartman' },
  { value: 'ECONOMY', label: 'Economy szoba' },
  { value: 'STANDARD', label: 'Standard szoba' },
  { value: 'SUPERIOR', label: 'Superior szoba' },
  { value: 'JUNIOR_SUITE', label: 'Junior Suite (Kislakosztály)' },
  { value: 'SUITE', label: 'Suite (Lakosztály)' },
  { value: 'MOBILHAZ', label: 'Mobilház' },
  { value: 'UDULOHAZ', label: 'Üdülőház' },
  { value: 'PARCELLA', label: 'Parcella' },
  { value: 'SATORHELY_KEMPINGHELY', label: 'Sátorhely/ Kempinghely' },
  { value: 'EGYEDI', label: 'Egyedi (pl: jurta)' },
  {
    value: 'PRIVAT_SZOBA_KOZOS_FURDOVEL',
    label: 'Privát szoba közös fürdővel (ifjúsági szálláshely)',
  },
  {
    value: 'PRIVAT_SZOBA_SAJAT_FURDOVEL',
    label: 'Privát szoba saját fürdővel (ifjúsági szálláshely)',
  },
  { value: 'HALOTERMI_AGY', label: 'Hálótermi ágy' },
  { value: 'EGYEB', label: 'Egyéb' },
];

export const floorOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az emeletet',
  },
  { value: '-1', label: 'Alagsor' },
  { value: '0', label: 'Földszint' },
  { value: '1', label: '1. emelet' },
  { value: '2', label: '2. emelet' },
  { value: '3', label: '3. emelet' },
  { value: '4', label: '4. emelet' },
  { value: '5', label: '5. emelet' },
  { value: '6', label: '6. emelet' },
  { value: '7', label: '7. emelet' },
  { value: '8', label: '8. emelet' },
  { value: '9', label: '9. emelet' },
  { value: '10', label: '10. emelet' },
];

export const genderOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a nemét',
  },

  { value: 'FERFI', label: 'Férfi' },
  { value: 'NO', label: 'Nő' },
  { value: 'EGYEB_VAGY_NEM_ISMERT', label: 'Egyéb' },
];

export const documentTypeOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az okmány típusát',
  },

  { value: 'SZEM_IG', label: 'Személyi' },
  { value: 'UTLEVEL', label: 'Útlevél' },
  { value: 'VEZ_ENG', label: 'Jogosítvány' },
  { value: 'EGYEB', label: 'EGYEB' },
];

export const countryOfResidenceOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a lakóhelyet',
  },

  { value: 'HU', label: 'HU' },
  { value: 'PL', label: 'PL' },
];
export const taxExemptionReasonOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a mentesség okát',
  },

  { value: 'im1', label: '18 év alatti' },
  { value: 'im2', label: 'Gyógyintézetben' },
  { value: 'im3', label: 'Közép-és felsőfokú oktatási' },
  { value: 'im4', label: 'Szolgálati kötelezettséget' },
  { value: 'im5', label: 'Hatósági, bírósági intézkedés folytán' },
  { value: 'im6', label: 'A településen székhellyel, telephellyel rendelkező' },
  { value: 'im7', label: 'Ideiglenes jellegű iparűzési tevékenységet végző' },
  { value: 'im8', label: 'A településen üdülőtulajdonnal' },
  { value: 'im9', label: 'Az egyház tulajdonában lévő épületben' },
  { value: 'im10', label: 'A településen lakóhellyel' },
  { value: 'im11', label: 'Önkormányzati rendeleti mentesség (Egyéb)' },
];

export const vatRateOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki az ÁFÁ-t',
  },
  { value: '0', label: '0%' },
  { value: '5', label: '5%' },
  { value: '18', label: '18%' },
  { value: '27', label: '27%' },
];

export const serviceCategoryOptions = [
  {
    selected: true,
    disabled: true,
    label: 'Válassza ki a kategóriát',
  },
  { value: 'FOOD', label: 'Étel' },
  { value: 'DRINK', label: 'Ital' },
  { value: 'WELLNESS', label: 'Wellness' },
  { value: 'OTHER', label: 'Egyéb' },
];
